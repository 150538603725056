import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import logger from '@next-insurance/logger';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { businessSelectors } from '../../business/store/business.selectors';
import { AppState } from '../../store';
import { PortalAuthService } from '../services/portal-auth.service';
import { coreSelectors } from '../store/core.selectors';

let store: Store<AppState>;

export const businessIdGuard: CanActivateFn = (): Observable<boolean> => {
  store = inject(Store<AppState>);
  const authService = inject(PortalAuthService);

  return combineLatest([store.select(coreSelectors.isUserLoggedIn), store.select(businessSelectors.getBusinessId)]).pipe(
    first(),
    map(([isUserLoggedIn, businessId]) => {
      if (!isUserLoggedIn || !businessId) {
        logger.warn('businessIdGuard: user is trying to load a page without being logged in');
        authService.logout();
        return false;
      }

      return true;
    }),
  );
};
