import { ApplicationRef, enableProdMode, NgModuleRef } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import logger from '@next-insurance/logger';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { IS_APP_LOADED, LAST_TAB_ID, TAB_ID } from './app/core/config/global-session-storage-items.config';
import { loggerOptions } from './app/core/config/logger.config';
import { sentryOptions } from './app/core/config/sentry.config';
import { environment } from './environments/environment';

logger.init(loggerOptions);

if (environment.production) {
  if (environment.sentryClientDsn) {
    Sentry.init(sentryOptions);
  }
  enableProdMode();
}

// ngZoneRunCoalescing causes a runtime error with NGX Smart Modal.
// once resolved - we can drop this file and set it to true
const setZoneFlag = location.search.includes('setZone');

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    ngZoneEventCoalescing: true,
    ngZoneRunCoalescing: setZoneFlag,
  })
  .then((moduleRef: NgModuleRef<AppModule>) => {
    if (!environment.production && environment.enableAngularDebugTools) {
      const applicationRef = moduleRef.injector.get(ApplicationRef);
      const componentRef = applicationRef.components[0];
      enableDebugTools(componentRef);
    }
  })
  .catch((error) => logger.error(error.message, error));

function markTabWithId(): void {
  const tabId = sessionStorage.getItem(TAB_ID);
  const lastTabId = localStorage.getItem(LAST_TAB_ID);

  if (!tabId) {
    const currentTabId = lastTabId ? +lastTabId.split('-')[0] + 1 : 0;
    const randomNum = Math.floor(Math.random() * 1000);
    sessionStorage.setItem(TAB_ID, `${currentTabId.toString()}-${randomNum}`);
    localStorage.setItem(LAST_TAB_ID, `${currentTabId.toString()}-${randomNum}`);
  }
}

window.addEventListener('beforeunload', () => {
  sessionStorage.removeItem(IS_APP_LOADED);
});

if (sessionStorage.getItem(IS_APP_LOADED)) {
  sessionStorage.removeItem(TAB_ID);
}

sessionStorage.setItem(IS_APP_LOADED, '1');
markTabWithId();
