import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import logger from '@next-insurance/logger';
import { WINDOW } from '@next-insurance/ng-core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, forkJoin, lastValueFrom, NEVER, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { publicPaths } from '../../../../server/config/public-paths.config';
import { businessActions } from '../../business/store/business.actions';
import { AuthErrorStatuses } from '../../login/models/auth-error-status.enum';
import { LoginTrackingService } from '../../login/services/login-tracking.service';
import { AppState } from '../../store';
import { QueryParams } from '../models/query-params.enum';
import { coreActions } from '../store/core.actions';
import { FeatureFlagsService } from './feature-flags.service';
import { HolidayThemeService } from './holiday-theme.service';
import { PortalAuthService } from './portal-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializationService {
  private featureFlagsService = inject(FeatureFlagsService);
  private translateService = inject(TranslateService);
  private holidayThemeService = inject(HolidayThemeService);
  private portalAuthService = inject(PortalAuthService);
  private store = inject(Store<AppState>);
  private httpClient = inject(HttpClient);
  private window = inject(WINDOW);
  private loginTrackingService = inject(LoginTrackingService);

  async initializeApp(): Promise<boolean> {
    if (this.isAfterLogin()) {
      this.loginTrackingService.trackLandingAfterNextLogin(this.window.location.search);
      await this.portalAuthService.exchangeLoginResponseToTokens();
      this.redirectToReturnUrlAfterNextLogin();
      await this.blockAppLoading();
    }

    if (this.isAfterLogout()) {
      await this.portalAuthService.navigateToNextLogin();
      await this.blockAppLoading();
    }

    const isAuthenticated = await this.isAuthenticated();
    if (!isAuthenticated && !this.isPublicPage()) {
      const returnUrl = this.getReturnUrl();
      await this.portalAuthService.navigateToNextLogin(returnUrl);
      await this.blockAppLoading();
    }

    return this.getInitialData();
  }

  private loadTranslateService(): Observable<any> {
    this.translateService.setDefaultLang('en');
    return this.translateService.use('en');
  }

  private isAfterLogin(): boolean {
    return this.window.location.pathname.includes('post-login-callback');
  }

  private blockAppLoading(): Promise<void> {
    return lastValueFrom(NEVER);
  }

  private getReturnUrl(): string {
    const queryParams = new URLSearchParams(this.window.location.search);

    this.checkForMlTokenError(queryParams);

    queryParams.set('return_url', this.window.location.pathname);

    return queryParams.toString();
  }

  private isPublicPage(): boolean {
    for (const path of publicPaths) {
      const pathRegex = new RegExp(path);
      if (pathRegex.test(this.window.location.pathname)) {
        return true;
      }
    }

    return false;
  }

  private isAfterLogout(): boolean {
    return this.window.location.pathname.includes('post-logout-callback');
  }

  private getInitialData(): Promise<boolean> {
    return lastValueFrom(
      forkJoin([
        this.featureFlagsService.loadFeatureFlags(),
        this.loadTranslateService(),
        this.holidayThemeService.loadHolidayTheme(),
      ]).pipe(
        map(([flagsLoaded, translationsLoaded, themeLoaded]) => {
          return flagsLoaded && translationsLoaded && themeLoaded;
        }),
      ),
    );
  }

  private async isAuthenticated(): Promise<boolean> {
    try {
      const businessId = await firstValueFrom(this.httpClient.get<string>('api/business/id'));
      this.setBusinessId(businessId);
      return true;
    } catch (error: any) {
      if ([401, 403].includes(error.status)) {
        this.setBusinessId('');
      } else {
        throw error;
      }
    }

    return false;
  }

  private setBusinessId(businessId: string): void {
    this.store.dispatch(businessActions.setBusinessId({ businessId }));
    this.store.dispatch(coreActions.setIsUserLoggedIn({ isUserLoggedIn: !!businessId }));
  }

  private redirectToReturnUrlAfterNextLogin(): void {
    const stateQueryParam = new URLSearchParams(this.window.location.search).get('state');
    const queryParams = new URLSearchParams(stateQueryParam);

    let returnUrl = queryParams.get('return_url');

    if (!returnUrl) {
      logger.error('no return url provided after next login');
      this.window.location.href = '/home';
      return;
    }

    queryParams.delete('return_url');
    if (queryParams.size > 0) {
      returnUrl += queryParams.size > 0 ? `?${queryParams.toString()}` : '';
    }

    this.window.location.href = returnUrl; // TODO fix this line to navigate without refresh the app
  }

  private checkForMlTokenError(queryParams: URLSearchParams): void {
    const mlTokenStatus = queryParams.get(QueryParams.MlTokenStatus) as AuthErrorStatuses;
    if (mlTokenStatus) {
      this.portalAuthService.setLoginErrorInCookie(JSON.stringify({ beStatusErrorCode: mlTokenStatus }));
      queryParams.delete(QueryParams.MlTokenStatus);
    }
  }
}
