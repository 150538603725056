import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { BillingTrackingService } from '../../billing/services/billing-tracking.service';
import { MobileAppEventId } from '../../core/models/mobile-app-event.model';
import { MobileAppEventsService } from '../../core/services/mobile-app-events.service';
import { ToastType } from '../../shared/components/toast/models/toast-type.enum';
import { toastActions } from '../../shared/components/toast/store/toast.actions';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { PaymentDataService } from '../payment.data.service';
import { PaymentTrackingService } from '../payment-tracking.service';
import { paymentActions } from './payment.actions';

@Injectable()
export class PaymentEffects {
  loadCombinedPaymentDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.loadCombinedPaymentDetails),
      mergeMap(() => {
        return this.paymentDataService.getCombinedPaymentDetails().pipe(
          map((combinedPaymentDetails) => {
            if (combinedPaymentDetails.paymentMethodDetails) {
              this.billingTrackingService.trackCurrentPaymentMethod(combinedPaymentDetails.paymentMethodDetails.type);
            }
            return paymentActions.setCombinedPaymentDetails({ combinedPaymentDetails });
          }),
          catchErrorAndLog(() => [paymentActions.setCombinedPaymentDetails({ combinedPaymentDetails: null })]),
        );
      }),
    ),
  );

  updatePaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.updatePaymentMethod),
      switchMap((action) =>
        this.paymentDataService.updatePaymentMethod(action.tokens, action.policyId, action.providerTokenId).pipe(
          mergeMap(() => {
            let successActions: Action[] = [];
            successActions.push(paymentActions.updatePaymentMethodSuccess({ shouldCloseMobileBanner: action.shouldCloseMobileBanner }));
            if (!(action.shouldCloseMobileBanner || this.mobileAppEventsService.shouldSendPaymentRefreshEvent())) {
              successActions.push(paymentActions.loadCombinedPaymentDetails());
            }

            return successActions;
          }),
          catchErrorAndLog((error: HttpErrorResponse) => {
            return of(paymentActions.updatePaymentMethodError({ errorStatusCode: error.error?.niStatusCode }));
          }),
        ),
      ),
    ),
  );

  updatePaymentMethodSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(paymentActions.updatePaymentMethodSuccess),
        tap(({ shouldCloseMobileBanner }) => {
          this.paymentTrackingService.trackUpdatePaymentMethod(true);
          if (shouldCloseMobileBanner && this.mobileAppEventsService.shouldSendPaymentRefreshEvent()) {
            this.mobileAppEventsService.notifyMobile({
              eventId: MobileAppEventId.LastFailedPaymentHandledEvent,
              closeWebview: true,
            });
          }
        }),
      ),
    { dispatch: false },
  );

  updatePaymentMethodError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.updatePaymentMethodError),
      tap(() => this.paymentTrackingService.trackUpdatePaymentMethod(false)),
      map((action) => [action.errorStatusCode, action.errorMessage]),
      map(([errorStatusCode, errorMessage]: [string, string]) => {
        const message = errorMessage || (errorStatusCode ? `PAYMENT.ERRORS.${errorStatusCode}` : null);
        return message
          ? toastActions.showToast({
              toastType: ToastType.Error,
              message,
              isAlreadyTranslated: !errorStatusCode,
            })
          : toastActions.showGeneralErrorToast();
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private paymentDataService: PaymentDataService,
    private paymentTrackingService: PaymentTrackingService,
    private billingTrackingService: BillingTrackingService,
    private mobileAppEventsService: MobileAppEventsService,
  ) {}
}
