import { ChangeDetectionStrategy, Component, Input, QueryList, ViewChildren } from '@angular/core';

import { Language } from '../../../enums/language.enum';
import { NavigationTab } from '../../../models/navigation-tab.enum';
import { Tab } from '../../../models/tab.model';
import { CoreTrackingService } from '../../../services/core-tracking.service';
import { LanguageTrackingService } from '../../../services/language-tracking.service';
import { PortalAuthService } from '../../../services/portal-auth.service';
import { ExpandableMenuItemComponent } from '../expandable-menu-item/expandable-menu-item.component';

@Component({
  selector: 'ni-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMenuComponent {
  @Input() tabs: Tab[];
  isMenuOpen = false;
  @ViewChildren('expandableMenuItem') expandableMenuItems: QueryList<ExpandableMenuItemComponent>;

  constructor(
    private coreTrackingService: CoreTrackingService,
    private languageTrackingService: LanguageTrackingService,
    private portalAuthService: PortalAuthService,
  ) {}

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  closeMenu(): void {
    this.isMenuOpen = false;
  }

  onTabClick(tab: Tab): void {
    this.coreTrackingService.trackNavigationThroughMenu(tab.id);
    this.closeMenu();
  }

  closeOpenedMenu(): void {
    this.expandableMenuItems.forEach((expandableMenu: ExpandableMenuItemComponent): void => {
      if (expandableMenu.expanded) {
        expandableMenu.closeExpand();
      }
    });
  }

  languageChanged(lang: Language): void {
    this.languageTrackingService.trackNavigationMenuLanguageSwitch(lang);
  }

  logout(): void {
    this.coreTrackingService.trackNavigationThroughMenu(NavigationTab.Logout);
    this.portalAuthService.logout();
  }
}
