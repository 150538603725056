import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NIError } from '@next-insurance/errors';
import { select, Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { timer } from 'rxjs';
import { first } from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';
import { businessSelectors } from '../../../../../business/store/business.selectors';
import { NavigationService } from '../../../../../core/services/navigation.service';
import { CrossSellTrackingService } from '../../../../services/cross-sell-tracking.service';

@Component({
  selector: 'ni-health-insurance-modal',
  templateUrl: './health-insurance-modal.component.html',
  styleUrls: ['./health-insurance-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class HealthInsuranceModalComponent implements OnInit {
  private navigationService = inject(NavigationService);
  private dynamicDialogRef = inject(DynamicDialogRef);
  private crossSellTrackingService = inject(CrossSellTrackingService);
  private destroyRef = inject(DestroyRef);
  private store = inject(Store);

  private isAutoClosing = false;
  TIMER_DELAY = 2300;

  ngOnInit(): void {
    timer(this.TIMER_DELAY)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.crossSellTrackingService.trackHealthInsuranceRedirect();
        this.navigationService.navigateTo(this.getHealthInsuranceLink(), true);
        this.isAutoClosing = true;
        this.dynamicDialogRef.close();
      });

    this.dynamicDialogRef.onClose.subscribe(() => {
      if (this.isAutoClosing === false) {
        this.crossSellTrackingService.trackHealthInsuranceCloseModalClicked();
      }
    });
  }

  private getHealthInsuranceLink(): string {
    let zipCode: string;
    let email: string;
    this.store.pipe(select(businessSelectors.getBusiness), first()).subscribe((business) => {
      zipCode = business.zipCode;
      email = business.emailAddress;
    });
    if (!zipCode || !email) {
      throw new NIError('Missing zip code or email address for health insurance cross sell.');
    }
    return `${environment.healthInsuranceVendorUrl}?email=${encodeURIComponent(email)}&zip=${zipCode}`;
  }
}
