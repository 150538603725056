import { FeatureFlagsMap } from '@next-insurance/core';
import { Action, createReducer, on } from '@ngrx/store';

import { featureFlagsActions } from './feature-flags.actions';

export interface FeatureFlagsState {
  featureFlagsMap: FeatureFlagsMap;
}

export const featureFlagsInitialState: FeatureFlagsState = {
  featureFlagsMap: {},
};

const reducer = createReducer(
  featureFlagsInitialState,
  on(featureFlagsActions.setFeatureFlags, (state, action) => ({
    ...state,
    featureFlagsMap: action.featureFlagsMap,
  })),
);

export function featureFlagsReducer(state: FeatureFlagsState, action: Action): FeatureFlagsState {
  return reducer(state, action);
}
