import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { MobileAppEventId } from '../core/models/mobile-app-event.model';
import { QueryParams } from '../core/models/query-params.enum';
import { MobileAppEventsService } from '../core/services/mobile-app-events.service';
import { NavigationService } from '../core/services/navigation.service';
import { DocumentCenterResourceType, documentCenterResourceTypeToPath } from '../document-center/models/document-center-resource-type.enum';
import { AppState } from './index';
import { navigationActions } from './navigation.actions';
import { selectQueryParam } from './router.store';

@Injectable()
export class NavigationEffects {
  navToHomePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toHomePage),
        tap(({ replaceUrl }) => {
          this.router.navigate(['/home'], { queryParamsHandling: 'preserve', replaceUrl });
        }),
      ),
    { dispatch: false },
  );

  navToVerification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toVerification),
        tap(() => {
          this.router.navigate(['/login/verification'], { queryParamsHandling: 'preserve', skipLocationChange: true });
        }),
      ),
    { dispatch: false },
  );

  navToAuthentication$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toAuthentication),
        withLatestFrom(this.store.select(selectQueryParam(QueryParams.ReturnUrl))),
        tap(([action, returnUrl]: [{ queryParams?: Record<string, string>; replaceUrl?: boolean }, string]) => {
          this.router.navigate([`/login/authentication`], {
            queryParamsHandling: 'merge',
            queryParams: { ...action.queryParams, [QueryParams.ReturnUrl]: returnUrl },
            replaceUrl: action.replaceUrl,
          });
        }),
      ),
    { dispatch: false },
  );

  navToCertificates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toCertificates),
        switchMap(() => this.mobileAppEventsService.notifyMobileAndBreakFlowIfNeeded({ eventId: MobileAppEventId.CertificatesNavigation })),
        tap(() => {
          this.router.navigate(['/certificates']);
        }),
      ),
    { dispatch: false },
  );

  navToCertificatesAutoTab$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toCertificatesAutoTab),
        tap(() => {
          this.router.navigate(['/certificates/auto-insurance-cards']);
        }),
      ),
    { dispatch: false },
  );

  navToThirdPartyChangesRequestReviewPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toThirdPartyChangesRequestReviewPage),
        tap((action: { requestId: string }) => {
          this.router.navigate(['/certificates/third-party-requests/changes'], {
            queryParams: { [QueryParams.RequestId]: action.requestId },
          });
        }),
      ),
    { dispatch: false },
  );

  navToThirdPartyNotificationsRequestReviewPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toThirdPartyNotificationsRequestReviewPage),
        tap((action: { requestId: string }) => {
          this.router.navigate(['/certificates/third-party-requests/notifications'], {
            queryParams: { [QueryParams.RequestId]: action.requestId },
          });
        }),
      ),
    { dispatch: false },
  );

  navToFollowersPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toFollowersPage),
        tap(({ replaceUrl }) => {
          this.router.navigate(['/followers'], { replaceUrl });
        }),
      ),
    { dispatch: false },
  );

  navToLiveCertificate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toLiveCertificate),
        tap((action: { liveCertificateId: string; openInNewTab?: boolean; type: string }) => {
          const link = `/public/certificates/live-certificate/${action.liveCertificateId}`;
          const openInCurrentTab = this.deviceDetectorService.isMobile() || !action.openInNewTab;
          if (openInCurrentTab) {
            this.router.navigate([link]);
          } else {
            this.navigationService.navigateTo(`${window.location.origin}${link}`, true);
          }
        }),
      ),
    { dispatch: false },
  );

  navToLiveCertificateRequestChanges$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toLiveCertificateRequestChanges),
        tap(() => {
          this.relativeNavigate('/third-party-requests/changes');
        }),
      ),
    { dispatch: false },
  );

  navToLiveCertificateRequestNotifications$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toLiveCertificateRequestNotifications),
        tap(() => {
          this.relativeNavigate('/third-party-requests/notifications');
        }),
      ),
    { dispatch: false },
  );

  navToLiveCertificateErrorPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toLiveCertificateErrorPage),
        tap(() => {
          this.router.navigate(['/public/certificates/live-certificate/error']);
        }),
      ),
    { dispatch: false },
  );

  navToCreateCustomCertificate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toCreateCustomCertificate),
        tap(() => {
          this.router.navigate(['/certificates/create-certificate']);
        }),
      ),
    { dispatch: false },
  );

  navToCustomCertificateReviewPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toCustomCertificateReviewPage),
        tap((action: { fromSampleCOI?: boolean }) => {
          if (action.fromSampleCOI) {
            this.router.navigate(['/certificates/create-certificate/review'], {
              queryParams: { [QueryParams.FromSampleCOI]: 'true' },
              queryParamsHandling: 'merge',
            });
          } else {
            this.router.navigate(['/certificates/create-certificate/review']);
          }
        }),
      ),
    { dispatch: false },
  );

  navToCustomCertificateCustomizationsPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toCustomCertificateCustomizationsPage),
        tap(() => {
          this.router.navigate(['/certificates/create-certificate/customizations']);
        }),
      ),
    { dispatch: false },
  );

  navToRequestChangesCustomizationsPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toRequestChangesCustomizationsPage),
        tap(() => {
          this.relativeNavigate('/customizations');
        }),
      ),
    { dispatch: false },
  );

  navToRequestChangesReviewPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toRequestChangesReviewPage),
        tap(() => {
          const url = this.router.url.split('/customizations')[0];
          this.router.navigate([`${url}/review`]);
        }),
      ),
    { dispatch: false },
  );

  navToError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toError),
        tap(() => {
          this.router.navigate(['/error']);
        }),
      ),
    { dispatch: false },
  );

  navToBilling$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toBilling),
        tap(() => this.router.navigateByUrl('/billing')),
      ),
    { dispatch: false },
  );

  back$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.back),
        switchMap(() => this.mobileAppEventsService.notifyMobileAndBreakFlowIfNeeded({ eventId: MobileAppEventId.Back }, false)),
        tap(() => this.location.back()),
      ),
    { dispatch: false },
  );

  navToClaimsPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toClaimsPage),
        tap(() => this.router.navigateByUrl('/claims')),
      ),
    { dispatch: false },
  );

  navToClaimsChatbot$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toClaimsChatbot),
        tap(() => {
          this.router.navigateByUrl('/claims/chatbot');
        }),
      ),
    { dispatch: false },
  );

  navToEditContactInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toEditContactInfo),
        tap(() => this.router.navigateByUrl('/business/contact/edit')),
      ),
    { dispatch: false },
  );

  navToEditRevenuePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toEditRevenuePage),
        tap(() => this.router.navigateByUrl('/business/revenue/edit')),
      ),
    { dispatch: false },
  );

  navToEditBusinessAddressPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toEditBusinessAddressPage),
        tap(() => this.router.navigateByUrl('/business/address/edit')),
      ),
    { dispatch: false },
  );

  navToAddLocationPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toAddLocationPage),
        tap(() => this.router.navigateByUrl('/business/add-location')),
      ),
    { dispatch: false },
  );

  navToEditLocationAddressPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toEditLocationAddressPage),
        tap((action: { locationId: string }) => {
          this.router.navigateByUrl(`business/edit-address/${action.locationId}`);
        }),
      ),
    { dispatch: false },
  );

  navToRemoveLocationPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toRemoveLocationPage),
        tap((action: { locationId: string }) => {
          this.router.navigateByUrl(`business/remove-location/${action.locationId}`);
        }),
      ),
    { dispatch: false },
  );

  navToEditMailingAddressPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toEditMailingAddressPage),
        tap(() => this.router.navigateByUrl('/business/mailing-address/edit')),
      ),
    { dispatch: false },
  );

  navToWcAudit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toWcAudit),
        tap(() => this.router.navigateByUrl('/wc-audit')),
      ),
    { dispatch: false },
  );

  navToBusinessPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toBusinessPage),
        tap(() => this.router.navigateByUrl('/business')),
      ),
    { dispatch: false },
  );

  navToAddAuthorizedUserPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toAddAuthorizedUserPage),
        tap(() => this.router.navigateByUrl('/business/add-authorized-user')),
      ),
    { dispatch: false },
  );

  navToCoveragePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toCoveragePage),
        tap(() => this.router.navigateByUrl('/coverage')),
      ),
    { dispatch: false },
  );

  navToEmptyPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toEmptyPage),
        tap(() => this.router.navigateByUrl('/empty-page')),
      ),
    { dispatch: false },
  );

  navToPolicyCoverage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toPolicyCoverage),
        tap((action: { policyId: number }) => {
          this.router.navigateByUrl(`/coverage/${action.policyId}`);
        }),
      ),
    { dispatch: false },
  );

  navToPolicyCoverageCustomization$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toPolicyCoverageCustomization),
        tap((action: { policyId: number }) => {
          this.router.navigateByUrl(`/coverage/${action.policyId}/customization`, { skipLocationChange: true });
        }),
      ),
    { dispatch: false },
  );

  ToDocumentCenter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toDocumentCenter),
        tap(() => {
          this.router.navigateByUrl('/document-center');
        }),
      ),
    { dispatch: false },
  );

  ToDocumentCenterCategoryPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toDocumentCenterCategoryPage),
        tap((action: { resourceType: DocumentCenterResourceType }) => {
          this.router.navigateByUrl(`/document-center/${documentCenterResourceTypeToPath.get(action.resourceType)}`);
        }),
      ),
    { dispatch: false },
  );

  navToCertificateOptionsPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toCertificateOptionsPage),
        tap(() => {
          this.router.navigateByUrl('/certificates/create-certificate/options');
        }),
      ),
    { dispatch: false },
  );

  navToCreateCertificatePreQuestions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.toCreateCertificatePreQuestionsPage),
        tap(() => {
          this.router.navigateByUrl('/certificates/create-certificate/pre-questions');
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private location: Location,
    private router: Router,
    private store: Store<AppState>,
    private deviceDetectorService: DeviceDetectorService,
    private mobileAppEventsService: MobileAppEventsService,
    private navigationService: NavigationService,
  ) {}

  private relativeNavigate(relativeUrl: string): void {
    const url = this.router.url.split('?')[0];
    this.router.navigate([`${url}${relativeUrl}`]);
  }
}
