import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { AppState } from '../../../store';
import { navigationActions } from '../../../store/navigation.actions';
import { Language } from '../../enums/language.enum';
import { FeatureFlags } from '../../models/feature-flags.enum';
import { HelpTip } from '../../models/help-tip.enum';
import { HolidayThemeElement } from '../../models/holiday-theme-element.enum';
import { LmsSerials } from '../../models/lms-serials.enum';
import { CoreTrackingService } from '../../services/core-tracking.service';
import { DynamicDialogService } from '../../services/dynamic-dialog.service';
import { FeatureFlagsService } from '../../services/feature-flags.service';
import { LanguageTrackingService } from '../../services/language-tracking.service';
import { MobileAppService } from '../../services/mobile-app.service';
import { NavigationService } from '../../services/navigation.service';
import { PortalAuthService } from '../../services/portal-auth.service';
import { coreSelectors } from '../../store/core.selectors';
import { HelpModalComponent } from '../help-modal/help-modal.component';
import { helpModalConfig } from '../help-modal/help-modal.config';

@Component({
  selector: 'ni-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  isUrgentUserMessageEnabled: boolean;
  isNavigationTabsVisible: boolean;
  isAlertBannersVisible: boolean;
  isMobileAppWebview: boolean;
  HolidayThemeElement = HolidayThemeElement;
  private isNavigationTabsVisibleSub$: Subscription;
  protected readonly HelpTip = HelpTip;

  constructor(
    private store: Store<AppState>,
    private coreTrackingService: CoreTrackingService,
    private featureFlagsService: FeatureFlagsService,
    private dynamicDialogService: DynamicDialogService,
    private changeDetectorRef: ChangeDetectorRef,
    private mobileAppService: MobileAppService,
    private languageTrackingService: LanguageTrackingService,
    private navigationService: NavigationService,
    private translateService: TranslateService,
    private portalAuthService: PortalAuthService,
  ) {}

  ngOnInit(): void {
    this.isUrgentUserMessageEnabled = this.featureFlagsService.isActive(FeatureFlags.UrgentUserMessage);
    this.isMobileAppWebview = this.mobileAppService.isMobileAppWebview();

    this.isNavigationTabsVisibleSub$ = this.store
      .select(coreSelectors.isNavigationTabsVisible)
      .pipe(
        tap((isNavigationTabsVisible: boolean) => {
          this.isNavigationTabsVisible = isNavigationTabsVisible;
          this.isAlertBannersVisible = isNavigationTabsVisible;
          this.changeDetectorRef.markForCheck();
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.isNavigationTabsVisibleSub$.unsubscribe();
  }

  goToHomePage(): void {
    this.coreTrackingService.trackLogoClick();
    this.store
      .select(coreSelectors.isUserLoggedIn)
      .pipe(
        first(),
        tap((isLoggedIn: boolean) => {
          if (isLoggedIn) {
            this.store.dispatch(navigationActions.toHomePage({}));
          } else {
            this.navigationService.navigateByLMS(LmsSerials.PublicSiteHomePage);
          }
        }),
      )
      .subscribe();
  }

  goToHelpCenter(): void {
    this.coreTrackingService.trackHelpButtonClick();
    if (this.featureFlagsService.isActive(FeatureFlags.HelpCenter)) {
      this.navigationService.navigateByLMS(LmsSerials.PublicSiteHelpCenter, true);
    } else {
      this.dynamicDialogService.open(HelpModalComponent, {
        header: this.translateService.instant(helpModalConfig.headerTranslateKey),
      });
    }
  }

  languageChanged(lang: Language): void {
    this.languageTrackingService.trackHeaderLanguageSwitch(lang);
  }

  logout(): void {
    this.coreTrackingService.trackLogoutClick();
    this.portalAuthService.logout();
  }
}
