import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LOB } from '@next-insurance/core';

import { InteractionType } from '../../core/models/interaction-type.enum';
import { DynamicDialogService } from '../../core/services/dynamic-dialog.service';
import { TrackingService } from '../../core/services/tracking.service';
import { CoverageCheckupModalComponent } from '../../coverage-checkup/components/coverage-checkup-modal/coverage-checkup-modal.component';
import { CoverageRiskLevel } from '../../coverage-checkup/enums/coverage-risk-level.enum';
import { BusinessPropertiesType } from '../../coverage-checkup/models/business-properties-type.enum';
import { pathParamsRegex } from '../../shared/utils/path-params-regex';
import { LOB_HI } from '../config/external-cross-sell-links.config';
import { CrossSellPolicyStatus } from '../models/cross-sell-policy-status.enum';
import { CrossSellResponse } from '../models/cross-sell-response.model';

const wcComplianceBannerPlacement = 'wc-compliance-banner';
const crossSellFromWebViewPlacement = 'cross-sell-from-web-view';

@Injectable({
  providedIn: 'root',
})
export class CrossSellTrackingService {
  constructor(
    private trackingService: TrackingService,
    private router: Router,
    private dynamicDialogService: DynamicDialogService,
  ) {}

  /* istanbul ignore next */
  private get eventPlacementByPageOrModal(): string {
    if (this.dynamicDialogService.openedDialogs.has(CoverageCheckupModalComponent.name)) {
      return 'coverage-checkup-modal';
    }

    return this.eventPlacementByPage;
  }

  /* istanbul ignore next */
  private get eventPlacementByPage(): string {
    if (this.router.url.includes('home')) {
      return 'home';
    }

    return 'coverage-page';
  }

  /* istanbul ignore next */
  trackClickAddNow(lobType: LOB | typeof LOB_HI, status: CrossSellPolicyStatus): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: this.eventPlacementByPageOrModal,
        name: 'click-add-now',
        interactionData: {
          lob: lobType,
          policyStatus: status,
        },
        eventName: `${this.eventPlacementByPageOrModal.replace(/-/g, ' ')} - CLICK add now button`,
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackClickNotify(lobType: LOB, status: boolean): void {
    const name = status ? 'click-notify' : 'cancel-notification';
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: this.eventPlacementByPageOrModal,
        name,
        interactionData: {
          lob: lobType,
        },
        eventName: `${this.eventPlacementByPageOrModal.replace(/-/g, ' ')} - CLICK notify button`,
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackCrossSellRedirectError(status: string, error: any): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Error,
        placement: this.eventPlacementByPageOrModal,
        name: 'failed-to-cross-sell',
        interactionData: {
          status,
          error,
        },
        eventName: `${this.eventPlacementByPageOrModal.replace(/-/g, ' ')} - ERROR failed to cross sell`,
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackWCComplianceBannerGetQuoteClicked(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: wcComplianceBannerPlacement,
        name: 'get-quote',
        eventName: 'wc compliance banner - CLICK get quote',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackWCComplianceBannerAlreadyInsuredDeclarationClicked(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: wcComplianceBannerPlacement,
        name: 'already-insured-user-declaration-button',
        eventName: 'wc compliance banner - CLICK already insured user declaration button',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackWCComplianceBannerCloseButtonClicked(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: wcComplianceBannerPlacement,
        name: 'close',
        eventName: 'wc compliance banner - CLICK close',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackWCComplianceBannerViewed(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.View,
        placement: wcComplianceBannerPlacement,
        name: 'banner',
        eventName: 'wc compliance banner - VIEW banner',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackWCComplianceBannerGetSuggestion(isSuccess: boolean, niStatusCode?: string): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: wcComplianceBannerPlacement,
      name: 'get-suggestion',
      interactionData: {
        isSuccess,
        status: niStatusCode,
      },
      eventName: 'wc compliance banner - API get suggestion',
    });
  }

  /* istanbul ignore next */
  trackClickLearnMore(lob: LOB | typeof LOB_HI): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: this.eventPlacementByPageOrModal,
        name: 'cross-sell-learn-more-button',
        interactionData: {
          lob,
        },
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackCrossSellLoadPoliciesError(status: string, error: any): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Error,
        placement: this.eventPlacementByPageOrModal,
        name: 'failed-to-load-policies',
        interactionData: {
          status,
          error,
        },
        eventName: `${this.eventPlacementByPageOrModal.replace(/-/g, ' ')} - ERROR failed to load policies`,
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackCrossSellFromWebViewLanding(lob: LOB): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.View,
        placement: crossSellFromWebViewPlacement,
        name: 'landing',
        interactionData: {
          lob,
        },
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackCrossSellFromWebViewGetSuggestion(isSuccess: boolean, lob: LOB, niStatusCode?: string): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Api,
        placement: crossSellFromWebViewPlacement,
        name: 'get-suggestion',
        interactionData: {
          isSuccess,
          lob,
          status: niStatusCode,
        },
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackManualOpenCoverageCheckupModal(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: this.eventPlacementByPage,
        name: 'manual-open-coverage-checkup-modal',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackAutomaticOpenCoverageCheckupModal(): void {
    const normalizedURL = this.normalizeURL(this.router.url);
    this.trackingService.track(
      {
        interactionType: InteractionType.View,
        placement: 'coverage-checkup-modal',
        name: 'automatic-open-coverage-checkup-modal',
        interactionData: {
          url: normalizedURL,
        },
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackRiskReviewStepViewed(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.View,
        placement: 'coverage-checkup-modal',
        name: 'risk-review-step-viewed',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackCoverageCheckupTileVisible(cobName: string, coverageRiskLevel: CoverageRiskLevel, missingLobs: LOB[]): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.View,
        placement: this.eventPlacementByPage,
        name: 'coverage-checkup-tile',
        interactionData: {
          cobName,
          coverageRiskLevel,
          missingLobs,
        },
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackCloseCoverageCheckupModal(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: 'coverage-checkup-modal',
        name: 'close-modal',
      },
      true,
    );
  }

  /* istanbul ignore next */
  private normalizeURL(url: string): string {
    if (!url) {
      return '';
    }

    return (
      url
        .split('?')[0]
        // replace id path params with *
        .replace(pathParamsRegex, '/*/')
    );
  }

  /* istanbul ignore next */
  trackCrossSellResponse(crossSellResponse: CrossSellResponse): void {
    this.trackingService.track({
      interactionType: InteractionType.Api,
      placement: 'cross-sell',
      name: 'get-opportunities',
      interactionData: crossSellResponse,
    });
  }

  /* istanbul ignore next */
  trackUpdateBusinessInfoClicked(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: 'coverage-checkup-modal',
        name: 'update-business-info-clicked',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackBusinessPropertiesStepViewed(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.View,
        placement: 'coverage-checkup-modal',
        name: 'business-properties-step-viewed',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackBusinessPropertiesAnswerChecked(businessPropertiesType: BusinessPropertiesType, isChecked: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'coverage-checkup-modal',
      name: isChecked ? 'business-properties-answer-checked' : 'business-properties-answer-unchecked',
      interactionData: {
        answer: businessPropertiesType,
      },
    });
  }

  /* istanbul ignore next */
  trackBusinessPropertiesStepCompleted(businessPropertiesAnswers: Record<BusinessPropertiesType, boolean>): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: 'coverage-checkup-modal',
        name: 'business-properties-step-completed',
        interactionData: {
          answers: businessPropertiesAnswers,
        },
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackExternalLobsStepViewed(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.View,
        placement: 'coverage-checkup-modal',
        name: 'external-lobs-step-viewed',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackExternalLobsAnswerChecked(lob: LOB, isChecked: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'coverage-checkup-modal',
      name: isChecked ? 'external-lobs-answer-checked' : 'external-lobs-answer-unchecked',
      interactionData: {
        answer: lob,
      },
    });
  }

  /* istanbul ignore next */
  trackExternalLobsNoneChecked(isChecked: boolean): void {
    this.trackingService.track({
      interactionType: InteractionType.Click,
      placement: 'coverage-checkup-modal',
      name: isChecked ? 'external-lobs-none-checked' : 'external-lobs-none-unchecked',
      interactionData: {
        answer: 'none',
      },
    });
  }

  /* istanbul ignore next */
  trackExternalLobsStepCompleted(externalLobsAnswers: Record<LOB, boolean>): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: 'coverage-checkup-modal',
        name: 'external-lobs-step-completed',
        interactionData: {
          answers: externalLobsAnswers,
        },
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackErrorUpdatingCoverageCheckup(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Error,
        placement: 'coverage-checkup-modal',
        name: 'error-updating-coverage-checkup',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackHealthInsuranceRedirect(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Navigation,
        placement: 'health-insurance-modal',
        name: 'redirect-HI-external-link',
      },
      true,
    );
  }

  /* istanbul ignore next */
  trackHealthInsuranceCloseModalClicked(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: 'health-insurance-modal',
        name: 'close-modal',
      },
      true,
    );
  }
}
