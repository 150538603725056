export enum Auth0ErrorStatus {
  AccessDenied = 'access_denied',
  MfaInvalidCode = 'mfa_invalid_code',
  MfaRegistrationRequired = 'mfa_registration_required',
  MfaRequired = 'mfa_required',
  TooManyAttempts = 'too_many_attempts',
  Unauthorized = 'unauthorized',
}

export enum Auth0ActionsErrorStatus {
  PhoneNumberIsNotVerified = 'auth0-action:phone-number-is-not-verified',
  MoreThanOneBusinessFound = 'auth0-action:more-than-one-user-exist',
  UserNotFound = 'auth0-action:user-not-found',
}
