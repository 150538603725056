import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LOB } from '@next-insurance/core';
import { Observable } from 'rxjs';

import { PaymentHistory } from '../billing/models/payment-history.model';
import { FeatureFlags } from '../core/models/feature-flags.enum';
import { ReceiptResponse } from '../core/models/receipt-response';
import { FeatureFlagsService } from '../core/services/feature-flags.service';
import { CombinedPaymentDetails } from './models/combined-payment-details.model';
import { PaymentMethodTokens } from './models/payment-method-tokens.model';
import { PaymentProviders } from './models/payment-providers.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentDataService {
  constructor(
    private httpClient: HttpClient,
    private featureFlagsService: FeatureFlagsService,
  ) {}

  /* istanbul ignore next */
  updatePaymentMethod(tokens: PaymentMethodTokens, policyId: number, providerTokenId: string): Observable<void> {
    if (this.featureFlagsService.isActive(FeatureFlags.UpdatePaymentMethodByPaymentPlan)) {
      return this.updatePaymentMethodByPaymentPlan(Object.values(tokens)[0], policyId, providerTokenId);
    }
    return this.httpClient.post<void>('/api/payment-details/update-payment-method', { tokens, policyId });
  }

  /* istanbul ignore next */
  updatePaymentMethodByPaymentPlan(token: string, policyId: number, providerTokenId: string): Observable<void> {
    return this.httpClient.post<void>('/api/payment-details/update-payment-method-by-payment-plan', {
      token,
      policyId,
      providerTokenId,
    });
  }

  /* istanbul ignore next */
  getCombinedPaymentDetails(): Observable<CombinedPaymentDetails> {
    return this.httpClient.get<CombinedPaymentDetails>('/api/business/combined-payment-details');
  }

  /* istanbul ignore next */
  getPaymentProviders(): Observable<PaymentProviders> {
    return this.httpClient.get<PaymentProviders>('/api/business/payment-details/payment-providers');
  }

  /* istanbul ignore next */
  getReceiptLink(): Observable<ReceiptResponse> {
    return this.httpClient.get<ReceiptResponse>('/api/business/payment-details/get_payments_receipt');
  }

  /* istanbul ignore next */
  getReceiptLinkByLob(lob: LOB): Observable<ReceiptResponse> {
    return this.httpClient.get<ReceiptResponse>('/api/payments/receipt-link-by-lob', {
      params: {
        lob,
      },
    });
  }

  /* istanbul ignore next */
  retryPayment(): Observable<void> {
    return this.httpClient.post<void>('/api/payment-details/retry-payment', {});
  }

  /* istanbul ignore next */
  getPaymentsHistory(lob: LOB): Observable<PaymentHistory[]> {
    return this.httpClient.get<PaymentHistory[]>('/api/payments/history', {
      params: {
        lob,
      },
    });
  }
}
