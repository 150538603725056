import { createAction, props } from '@ngrx/store';

import { AbTestsMap } from '../models/ab-test.model';
import { AbTestSection } from '../models/ab-test-section.enum';

export const abTestingActions = {
  setSectionAbTests: createAction(
    '[A/B Testing] set A/B tests',
    props<{
      abTestsMap: AbTestsMap;
      section: AbTestSection;
    }>(),
  ),
  resetSectionAbTests: createAction('[A/B Testing] reset A/B tests', props<{ section: AbTestSection }>()),
  setSectionAbTestsLoaded: createAction(
    '[A/B Testing] set section A/B tests loaded indicator',
    props<{
      section: AbTestSection;
      isLoaded: boolean;
    }>(),
  ),
};
