import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ButtonType } from '@next-insurance/ni-material';
import { Store } from '@ngrx/store';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { combineLatest, Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';

import { FullstoryEvent } from '../../../core/models/fullstory-event.enum';
import { FullStoryService } from '../../../core/services/fullstory.service';
import { CrossSellPolicy } from '../../../cross-sell/models/cross-sell-policy.model';
import { CrossSellService } from '../../../cross-sell/services/cross-sell.service';
import { CrossSellTrackingService } from '../../../cross-sell/services/cross-sell-tracking.service';
import { crossSellSelectors } from '../../../cross-sell/store/cross-sell.selectors';
import { AppState } from '../../../store';
import { CoverageCheckupDetails } from '../../models/coverage-checkup-details.model';
import { CoverageCheckupService } from '../../services/coverage-checkup.service';
import { CoverageCheckupStepComponent } from '../coverage-checkup-step/coverage-checkup-step.component';

@Component({
  selector: 'ni-coverage-checkup-risk-review-step',
  templateUrl: './coverage-checkup-risk-review-step.component.html',
  styleUrls: ['./coverage-checkup-risk-review-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverageCheckupRiskReviewStepComponent extends CoverageCheckupStepComponent implements OnInit {
  protected readonly ButtonType = ButtonType;
  coverageCheckupDetails$: Observable<CoverageCheckupDetails>;
  criticalCoveragesCrossSell$: Observable<CrossSellPolicy[]>;
  onDestroy$: Subject<void> = new Subject();
  isHealthCrossSellVisible: boolean;

  constructor(
    private store: Store<AppState>,
    private coverageCheckupService: CoverageCheckupService,
    private crossSellTrackingService: CrossSellTrackingService,
    private dynamicDialogRef: DynamicDialogRef,
    private fullStoryService: FullStoryService,
    private crossSellService: CrossSellService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.crossSellTrackingService.trackRiskReviewStepViewed();
    this.coverageCheckupDetails$ = this.store.select(crossSellSelectors.getCoverageCheckupDetails);
    this.criticalCoveragesCrossSell$ = combineLatest([
      this.store.select(crossSellSelectors.getPolicies),
      this.store.select(crossSellSelectors.getCoverageCheckupDetails),
    ]).pipe(
      map(([crossSellPolicies, { lobsCoverageDetails }]: [CrossSellPolicy[], CoverageCheckupDetails]) =>
        this.coverageCheckupService.getCriticalCoveragesCrossSell(crossSellPolicies, lobsCoverageDetails),
      ),
      takeUntil(this.onDestroy$),
    );

    this.dynamicDialogRef.onClose.pipe(first()).subscribe(() => {
      this.onDestroy$.next();
    });
    this.isHealthCrossSellVisible = this.crossSellService.isHealthCrossSellVisible();
  }

  updateBusinessInfo(): void {
    this.crossSellTrackingService.trackUpdateBusinessInfoClicked();
    this.fullStoryService.fireEvent(FullstoryEvent.CoverageCheckupUpdateBusinessInfoClicked);
    this.moveToNextStep.emit();
  }
}
