export enum LmsSerials {
  PublicSiteHomePage = 65555,
  TermsOfUse = 99049,
  PrivacyPolicy = 99050,
  PublicSiteHelpCenter = 99051,
  PublicSiteFaqWC = 992856085,
  PublicSiteFaqGL = 992856083,
  PublicSiteFaqBP = 992856726,
  PublicSiteFaqCA = 992856084,
  PublicSiteFaqCP = 992856088,
  PublicSiteFaqIM = 992856089,
  PublicSiteFaqPL = 992856087,
  PublicSiteWhatIsWcAudit = 992856356,
  QuestionnaireV2ForRich = 992856740,
  PublicSiteWCPage = 992856532,
  PublicSiteGLPage = 992856533,
  PublicSiteBPPage = 992856727,
  PublicSiteCAPage = 992856534,
  PublicSiteCPPage = 992856535,
  PublicSiteIMPage = 992856536,
  PublicSitePLPage = 992856537,
  PublicSiteReferralProgram = 992856812,
  PortalAgentsLoginPage = 992856615,
  PublicSiteHealthInsurancePage = 992856898,
}
