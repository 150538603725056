import { Action, createReducer, on } from '@ngrx/store';

import { AbTestsMap } from '../models/ab-test.model';
import { AbTestSection } from '../models/ab-test-section.enum';
import { abTestingActions } from './ab-testing.actions';

export interface AbTestingState {
  abTestsMap: AbTestsMap;
  isLoaded: Partial<Record<AbTestSection, boolean>>;
}

export const abTestingInitialState: AbTestingState = {
  abTestsMap: {},
  isLoaded: {},
};

const reducer = createReducer(
  abTestingInitialState,
  on(abTestingActions.setSectionAbTestsLoaded, (state, action) => ({
    ...state,
    isLoaded: {
      ...state.isLoaded,
      [action.section]: action.isLoaded,
    },
  })),
  on(abTestingActions.setSectionAbTests, (state, action) => ({
    ...state,
    abTestsMap: { ...state.abTestsMap, ...action.abTestsMap },
    isLoaded: {
      ...state.isLoaded,
      [action.section]: true,
    },
  })),
  on(abTestingActions.resetSectionAbTests, (state, action) => {
    const updatedAbTests = Object.values(state.abTestsMap).filter((abTest) => abTest.section !== action.section);
    const updatedAbTestsMap = Object.fromEntries(updatedAbTests.map((abTest) => [abTest.ab_test_name, abTest]));
    return {
      ...state,
      abTestsMap: updatedAbTestsMap,
      isLoaded: {
        ...state.isLoaded,
        [action.section]: true,
      },
    };
  }),
);

export function abTestingReducer(state: AbTestingState, action: Action): AbTestingState {
  return reducer(state, action);
}
