import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginV2DataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  logout(): Observable<void> {
    return this.httpClient.post<void>('/api/public/authentication/logout/v2', {});
  }

  /* istanbul ignore next */
  exchangeCodeForTokens(code: string, codeVerifier: string): Observable<void> {
    return this.httpClient.get<void>(`/api/public/authentication/exchange-code-for-tokens`, {
      params: {
        code,
        codeVerifier,
      },
    });
  }

  setVerifier(verifier: string): Observable<void> {
    return this.httpClient.post<void>('/api/public/authentication/set-verifier', { verifier });
  }
}
