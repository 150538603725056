import { Injectable } from '@angular/core';
import { FeatureFlagsMap } from '@next-insurance/core';
import { FeatureFlagsCookieForTesting, NiFeatureFlagsService } from '@next-insurance/ng-core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { featureFlagsActions } from '../../feature-flags/store/feature-flags.actions';
import { featureFlagsSelectors } from '../../feature-flags/store/feature-flags.selectors';
import { catchErrorAndLog } from '../../shared/utils/catch-error-and-log.utils';
import { AppState } from '../../store';
import { FeatureFlags } from '../models/feature-flags.enum';
import { FeatureFlagsDataService } from './feature-flags.data.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  constructor(
    private store: Store<AppState>,
    private niFeatureFlagsService: NiFeatureFlagsService,
    private featureFlagsDataService: FeatureFlagsDataService,
  ) {}

  private get featureFlags(): FeatureFlagsMap {
    let featureFlags: FeatureFlagsMap;

    this.store.pipe(select(featureFlagsSelectors.getFeatureFlagsMap), first()).subscribe((featureFlagsMap: FeatureFlagsMap) => {
      featureFlags = featureFlagsMap;
    });

    return featureFlags;
  }

  isActive(featureName: FeatureFlags): boolean {
    const { featureFlags } = this;
    return !!(featureFlags[featureName] && featureFlags[featureName].enabled);
  }

  loadFeatureFlags(): Observable<boolean> {
    const { on: turnOnFeatureFlags, off: turnOffFeatureFlags } = this.niFeatureFlagsService.getAdditionalFeatureFlagsConfig(
      FeatureFlagsCookieForTesting.Portal,
    );

    return this.featureFlagsDataService.getFeatureFlags().pipe(
      map((featureFlagsMap: FeatureFlagsMap) => {
        this.store.dispatch(
          featureFlagsActions.setFeatureFlags({
            featureFlagsMap: this.buildFeatureFlagsMap(featureFlagsMap, turnOnFeatureFlags, turnOffFeatureFlags),
          }),
        );
        return true;
      }),
      catchErrorAndLog(() => {
        return of(false);
      }),
    );
  }

  private buildFeatureFlagsMap(
    featureFlagsMap: FeatureFlagsMap,
    turnOnFeatureFlags: string[],
    turnOffFeatureFlags: string[],
  ): FeatureFlagsMap {
    const featureFlags = { ...featureFlagsMap };

    turnOnFeatureFlags.forEach((name) => {
      featureFlags[name] = { ...featureFlags[name], enabled: true };
    });

    turnOffFeatureFlags.forEach((name) => {
      featureFlags[name] = { ...featureFlags[name], enabled: false };
    });

    return featureFlags;
  }
}
